@import '../../../styles/main.scss';

.editor-wrapper {
  margin: 1rem 0;
  border: 1px solid $ws-color-secondary;
  border-radius: 4px;
  background-color: $white;

  .editor-controls {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid $ws-color-secondary-light;
  }

  .editor-style-button {
    color: $gray-500;
    cursor: pointer;
  }

  .style-applied {
    color: $ws-color-secondary;
  }

  .editor-body {
    padding: 1rem;
  }

  .public-DraftEditor-content {
    min-height: 10.75rem;
  }
}

.editor-read-only {
  border-color: $gray-200;

  .editor-body {
    padding: 2rem 1.5rem;;
  }
}
