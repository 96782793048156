// CUSTOM

$ws-color-primary: #546e7a;
$ws-color-secondary: #7a6154;
$ws-color-success: #5DA271;
$ws-color-info: lighten($ws-color-primary, 25%);
$ws-color-warning: #F98948;
$ws-color-danger: #DB2B39;
$ws-color-light: #f9fafb;
$ws-color-dark: #080B0C;

$ws-color-primary-light: lighten($ws-color-primary, 25%);
$ws-color-primary-dark: darken($ws-color-primary, 15%);
$ws-color-secondary-light: lighten($ws-color-secondary, 25%);
$ws-color-secondary-dark: darken($ws-color-secondary, 15%);
$ws-color-success-light: lighten($ws-color-success, 25%);
$ws-color-success-dark: darken($ws-color-success, 15%);
$ws-color-info-light: lighten($ws-color-info, 25%);
$ws-color-info-dark: darken($ws-color-info, 15%);
$ws-color-warning-light: lighten($ws-color-warning, 25%);
$ws-color-warning-dark: darken($ws-color-warning, 15%);
$ws-color-danger-light: lighten($ws-color-danger, 25%);
$ws-color-danger-dark: darken($ws-color-danger, 15%);

// MEDIA QUERIES

$ws-screen: 'only screen';

$ws-xs-lower: 0px;
$ws-xs-upper: 575.98px;
$ws-sm-lower: 576px;
$ws-sm-upper: 767.98px;
$ws-md-lower: 768px;
$ws-md-upper: 991.98px;
$ws-lg-lower: 992px;
$ws-lg-upper: 1199.98px;
$ws-xlg-lower: 1200px;
$ws-xlg-upper: 1399.98px;
$ws-xxlg-lower: 1400px;
$ws-xxlg-upper: 99999px;

$ws-xs-up: $ws-screen;
$ws-xs-only: '#{$ws-screen} and (min-width:#{$ws-xs-lower}) and (max-width:#{$ws-xs-upper})';
$ws-sm-up: '#{$ws-screen} and (min-width:#{$ws-sm-lower})';
$ws-sm-only: '#{$ws-screen} and (min-width:#{$ws-sm-lower}) and (max-width:#{$ws-sm-upper})';
$ws-md-up: '#{$ws-screen} and (min-width:#{$ws-md-lower})';
$ws-md-only: '#{$ws-screen} and (min-width:#{$ws-md-lower}) and (max-width:#{$ws-md-upper})';
$ws-lg-up: '#{$ws-screen} and (min-width:#{$ws-lg-lower})';
$ws-lg-only: '#{$ws-screen} and (min-width:#{$ws-lg-lower}) and (max-width:#{$ws-lg-upper})';
$ws-xlg-up: '#{$ws-screen} and (min-width:#{$ws-xlg-lower})';
$ws-xlg-only: '#{$ws-screen} and (min-width:#{$ws-xlg-lower}) and (max-width:#{$ws-xlg-upper})';
$ws-xxlg-up: '#{$ws-screen} and (min-width:#{$ws-xxlg-lower})';
$ws-xxlg-only: '#{$ws-screen} and (min-width:#{$ws-xxlg-lower}) and (max-width:#{$ws-xxlg-upper})';

// COLORS

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #e91916;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$primary: $ws-color-primary;
$secondary: $ws-color-secondary;
$success: $ws-color-success;
$info: $ws-color-info;
$warning: $ws-color-warning;
$danger: $ws-color-danger;
$light: $ws-color-light;
$dark: $ws-color-dark;

$body-bg: $ws-color-light;
$body-color: $ws-color-dark;

// TYPOGRAPHY

$font-family-sans-serif: Quicksand, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-family-monospace: 'Roboto Mono', SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$font-size-base: 0.88rem;
$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;
$headings-margin-bottom: 2rem;

// LINKS

$link-color: $ws-color-info;
$link-decoration: none;

// CARD

$card-spacer-x: .4rem;
$card-spacer-y: .4rem;
$card-border-width: 0px;
$card-cap-color: #FFFFFF;
$card-cap-bg: $ws-color-primary-dark;

// LIST GROUP

$list-group-border-radius: 0px;
$list-group-item-padding-y: .5rem;
$list-group-item-padding-x: .5rem;
