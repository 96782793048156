@import '../../../styles/main.scss';

.theme-color-one { color: $ws-color-primary }
.theme-color-one-light { color: $ws-color-primary-light }
.theme-color-one-dark { color: $ws-color-primary-dark }

.theme-color-two { color: $ws-color-secondary }
.theme-color-two-light { color: $ws-color-secondary-light }
.theme-color-two-dark { color: $ws-color-secondary-dark }

.theme-color-three { color: $ws-color-success }
.theme-color-three-light { color: $ws-color-success-light }
.theme-color-three-dark { color: $ws-color-success-dark }

.theme-color-four { color: $ws-color-info }
.theme-color-four-light { color: $ws-color-info-light }
.theme-color-four-dark { color: $ws-color-info-dark }

.theme-color-five { color: $ws-color-warning }
.theme-color-five-light { color: $ws-color-warning-light }
.theme-color-five-dark { color: $ws-color-warning-dark }

.theme-color-six { color: $ws-color-danger }
.theme-color-six-light { color: $ws-color-danger-light }
.theme-color-six-dark { color: $ws-color-danger-dark }

.theme-color-seven { color: $ws-color-light }

.theme-color-eight { color: $ws-color-dark }
